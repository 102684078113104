<template>
    <div>
        <section class="col-12 col-md-11 col-lg-10 mx-auto p-dash-cliente-master bg-login-authentic">
            <div class="col-12 col-md-12 col-lg-3 text-center pt-4">
                <img v-if="logo" :src="logo" alt="Logo" class="logo-login">
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-3 pt-4 order-2 order-md-2 order-lg-1">
                        <div class="card card-2 mb-0" style="background-color: var(--color);">
                            <div class="card-body pb-3">
                                <a href="" title="">
                                    <div class="d-dash-ps">
                                        <div class="mb-3 d-ico-dash-cliente">
                                            <img src="~@/assets/img/icons/ico-esqueceusenha.png"
                                                class="ico-dash-cliente-5" alt="Qsuíte">
                                        </div>
                                        <div class="">
                                            <h1 class="mb-1 font-weight-medium text-white font-17">Acontece! Quem nunca
                                                esqueceu a senha?</h1>
                                            <p class="text-white pt-3">Nos informe o seu CPF ou CNPJ e receba instruções
                                                para criar uma nova senha. Ao clicar em enviar verifique suas caixas de
                                                e-mail e spam ou SMS.</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-7 order-1 order-md-1 order-lg-2" v-if="step == 1">
                        <div class="col-12 pt-4">
                            <h2 class="tit-authentic">Informe o CPF ou CNPJ do titular</h2>
                        </div>
                        <form novalidate @submit.prevent="step1">
                            <div class="col-12 pt-2">
                                <input type="text" class="form-control inputs-login" placeholder="CPF | CNPJ"
                                    v-model="cpf_cnpj" v-mask="['###.###.###-##', '##.###.###/####-##']"
                                    v-validate="'required'" name="cpf_cnpj"
                                    :class="{ 'is-invalid': errors.has('cpf_cnpj') }">
                            </div>
                            <div class="col-12 pt-3">
                                <button type="submit" class="btn btn-info button-login"
                                style="background: #02b475">Continuar</button>
                            </div>
                        </form>
                    </div>

                    <!-- step 2 -->
                    <div class="col-12 col-md-12 col-lg-7 order-1 order-md-1 order-lg-2" v-if="step == 2">
                        <div class="col-12 pt-4">
                            <h2 class="tit-authentic">Olá. Antes de começar, nos ajude a manter seus dados seguros</h2>
                            <p>Vamos te enviar um código de segurança que garante que só você tenha acesso a seus dados.
                                Escolha uma das opções de envio a seguir:</p>
                        </div>
                        <form novalidate @submit.prevent="step2">
                            <div class="col-12 pb-4 pt-3">
                                 <div class="custom-control custom-radio align-items-center">
                                    <input type="radio" class="custom-control-input" v-model="type"
                                        id="customControlValidation" value="CELLPHONE" name="code-type">
                                    <label class="custom-control-label pt-0" for="customControlValidation">
                                        <b>Enviar código de segurança por SMS</b><br>
                                        ****{{ user.cellphone.substr(-4, user.cellphone.length) }}
                                    </label>
                                </div> 
                                <div class="custom-control custom-radio align-items-center pt-4">
                                    <input type="radio" class="custom-control-input" id="customControlValidation2"
                                        v-model="type" value="EMAIL" v-validate="'required'" name="code-type">
                                    <label class="custom-control-label pt-0" for="customControlValidation2">
                                        <b>Enviar código de segurança por e-mail</b><br>
                                        {{ user.email }}
                                    </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn btn-info button-continuar"
                                    style="background: #02b475">Enviar</button>
                            </div>
                        </form>
                        <div class="col-12 pt-4">
                            <p class="m-0">Não reconhece os dados das opções de envio? <a href="#"><b>Fale com o
                                        suporte</b></a></p>
                        </div>
                    </div>

                    <!-- step 3-->
                    <div class="col-12 col-md-12 col-lg-7 order-1 order-md-1 order-lg-2" v-if="step == 3">
                         <div class="col-12">
                    <!-- <div class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show mt-4" role="alert">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <strong>Oops, não foi possível enviar o código de segurança por SMS.</strong><br>
                        <p class="m-0">Pedimos desculpas pelo problema, mas essa opção de envio está temporariamente indisponível.</p>
                    </div> -->
                </div> 
                        <div class="col-12 pt-4">
                            <h2 class="tit-authentic">Informe o código de segurança</h2>
                            <p>Enviamos um código de segurança de 6 dígitos para <b>{{ selected }}.</b></p>
                        </div>
                        <form name="token" novalidate @submit.prevent="step3">
                            <div class="col-12 pt-2">
                                <input type="tel" class="form-control inputs-login" placeholder="Código de segurança"
                                    v-model="token" v-validate="'required'" name="token"
                                    :class="{ 'is-invalid': errors.has('token') }">
                                <div class="col-12 pt-2">
                                    <p>O código enviado expira em <b>{{ timer }}</b></p>
                                </div>
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn btn-info button-continuar"
                                style="background: #02b475" >Validar</button>
                            </div>
                        </form>
                        <div class="col-12 pt-4">
                            <p>Não está recebendo o código de segurança? <a href="javascript:void(0);"
                                    @click="step = 2">Tente outra opção de envio.</a></p>
                        </div>
                        <div class="col-12">
                            <button type="button" class="btn btn-info button-codigo" @click="step2"
                            style="background: #02b475" >Reenviar código de segurança</button>
                        </div>
                    </div>

                    <!-- step 4 -->
                    <div class="col-12 col-md-12 col-lg-7 order-1 order-md-1 order-lg-2" v-if="step == 4">
                        <div class="col-12 pt-4">
                            <h2 class="tit-authentic">Digite sua nova senha</h2>
                        </div>
                        <form name="password" novalidate @submit.prevent="update">
                            <div class="col-12 pt-2">
                                <input type="password" class="form-control inputs-login" placeholder="********"
                                    v-model="password" v-validate="'required'" name="password" ref="password"
                                    :class="{ 'is-invalid': errors.has('password') }">
                            </div>
                            <div class="col-12 pt-2">
                                <input type="password" class="form-control inputs-login" placeholder="********"
                                    v-validate="'required|confirmed:password'" name="confirm_password"
                                    :class="{ 'is-invalid': errors.has('confirm_password') }">
                            </div>
                            <div class="col-12 pt-4">
                                <button type="submit" class="btn btn-info button-continuar"
                                style="background: #02b475">Alterar senha</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <br>
        </section>
        <section class="col-12 col-md-11 col-lg-10 mx-auto p-dash-cliente-master">
            <BaseLoginFooter />
        </section>
    </div>
</template>
<script>
import RecoveryService from "@/services/resources/RecoveryService";
import ThemeService from "@/services/resources/ThemeService";
import Cookies from 'js-cookie';

const serviceTheme = ThemeService.build();
import moment from 'moment';

const service = RecoveryService.build();
export default {
    data() {
        return {
            step: 1,
            cpf_cnpj: null,
            token: null,
            password: null,
            type: null,
            user: {},
            timer: '05:00',
            interval: null,
            logo: null
        }
    },
    computed: {
        selected() {
            return this.type == 'CELLPHONE' ? this.user.cellphone : this.user.email;
        }
    },
    methods: {
        fetchTheme() {
            let data = {
                domain: window.location.hostname
            };

            serviceTheme
                .search(data)
                .then(resp => {
                    resp.forEach(element => {
                        switch (element.key) {
                            case 'SETTINGS_LOGO':
                                this.logo = element.value;
                                break;
                        }
                    });
                });
        },
        step1() {
            this.$validator.validateAll().then((result) => {
                if (result) {

                    let pathname = window.location.pathname;
                    let role_id = null;
                    let company_id = Cookies.get('company_id');

                    pathname = pathname.split('/')[1];

                    switch(pathname) {
                        case 'admin':
                            role_id = 4;
                        break;
                        case 'cliente':
                            role_id = 2;
                        break;
                        case 'sindico':
                            role_id = 3;
                        break;
                    }

                    let data = {
                        cpf: this.cpf_cnpj,
                        role_id
                    }

                    if(company_id) {
                        data['company_id'] = company_id;
                    }

                    service
                        .search(data)
                        .then(resp => {
                            this.user = resp;
                            this.step = 2;
                        })
                        .catch(err => {
                            this.$bvToast.toast('Esse CPF/CNPJ não está no nosso sistema.', {
                                title: 'CPF/CNPJ não encontrato',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        });
                }
            });
        },
        step2() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    let data = {
                        user_id: this.user.id,
                        type: this.type
                    }
                    service
                        .create(data)
                        .then(resp => {
                            this.step = 3;
                            this.countdown();
                        })
                        .catch(err => {
                            this.$bvToast.toast('Houve um erro no envio do e-mail, tente novamente mais tarde.', {
                                title: 'Erro interno',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        });
                }
            });
        },
        step3() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    let data = {
                        token: this.token,
                        url: 'validate'
                    }
                    service
                        .create(data)
                        .then(resp => {
                            this.step = 4;
                        })
                        .catch(err => {
                            this.$bvToast.toast('Esse código está incorreto.', {
                                title: 'Código incorreto',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        });
                }
            });
        },
        update() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    let data = {
                        id: this.user.id,
                        password: this.password,
                        token: this.token
                    }
                    service
                        .update(data)
                        .then(resp => {
                            let data = {
                                username: this.user.email,
                                password: this.password,
                            };

                            this.$store
                                .dispatch("loginRequest", data)
                                .then(() => {
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        })
                        .catch(err => {
                            this.$bvToast.toast('Houve um erro na alteração da senha, tente novamente mais tarde.', {
                                title: 'Erro interno',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        });
                }
            });
        },
        countdown() {
            let vm = this;
            // Set the date we're counting down to
            var countDownDate = moment().add(5, 'minutes').toDate().getTime();

            // Update the count down every 1 second
            var x = setInterval(function () {

                // Get today's date and time
                var now = new Date().getTime();

                // Find the distance between now and the count down date
                var distance = countDownDate - now;

                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                // Output the result in an element with id="demo"
                vm.timer = minutes + ":" + seconds;

                // If the count down is over, write some text 
                if (distance < 0) {
                    clearInterval(x);
                }
            }, 1000);
        }
    },
    mounted() {
        for (let i = 0; i < 100; i++)
            clearInterval(i);
        this.fetchTheme();
    }
}
</script>