import Rest from '@/services/Rest';

/**
 * @typedef {RecoveryService}
 */
export default class RecoveryService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/recovery'
}
